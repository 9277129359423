import './404.scss';

import React from 'react';

import Layout from 'components/layouts/Base';

const NotFoundPage: React.FC = () => {
  return (
    <Layout title="Four Oh Four">
      <div className="not-found">
        <h1>Sorry, we couldn&rsquo;t find what you were looking for.</h1>
        <p>
          Click here to go <a href="/">home</a>.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
